export enum SectionName {
  AI_JOURNEY_SETTINGS = 'ai-journey-settings',
  AFFILIATE_USER_MANAGEMENT = 'affiliate-user-management',
  USER_ACCOUNT_MANAGEMENT = 'user-account-management',
  ACCOUNT_MANAGEMENT = 'account-management',
  ADMIN_SETTINGS = 'admin-settings',
  COMPANY_SETTINGS = 'company-settings',
  COMPANY_OVERVIEW = 'company-overview',
  COMPANY_REGIONS = 'company-regions',
  CONCIERGE_SETTINGS = 'concierge-settings',
  CONNECTED_APPS = 'connected-apps',
  CUSTOM_ATTRIBUTES_SETTINGS = 'custom-attribute-settings',
  EMAIL_SETTINGS = 'email-settings',
  EMAIL_TEMPLATES = 'email-templates',
  EMAIL_LIST_MAINTENANCE = 'email-list-maintenance',
  EMAIL_SUPPRESSION_LIST = 'email-suppression',
  FEATURE_FLAGS = 'feature-flags',
  LINK_TRACKING = 'link-tracking',
  LEGAL = 'legal',
  MESSAGING_SERVICE_SETTINGS = 'message-service-settings',
  MESSAGE_SETTINGS = 'message-settings',
  PRODUCTS = 'products',
  REMOVE_SUBSCRIBER = 'remove-subscriber',
  SSO_MANAGEMENT = 'sso-management',
  SUBSCRIBER_TOOLS = 'subscriber-tools',
  SUBSCRIBER_UPLOAD = 'subscriber-upload',
  SFTP_ACCOUNT_MANAGEMENT = 'sftp-account-settings',
  FIDO_2_SANDBOX = 'fido-2-sandbox',
  AUTHENTICATOR_MANAGEMENT = 'authenticator-management',
  EDS = 'data-sync-service',
  CCK = 'custom-company-keywords',
}
